import axios from "axios";

class Cost {
  getAllCategory() {
    return axios.get(`/cost/category`);
  }
  addCategory(data) {
    return axios.post(`/cost/category`, data);
  }
  changeCategory(data) {
    return axios.put(`/cost/category`, data);
  }
  deleteCategory(id) {
    return axios.delete(`/cost/category`, { data: { id } });
  }
  ///
  getAllCost(params) {
    return axios.get(`/cost`, { params });
  }
  addCost(data) {
    return axios.post(`/cost`, data);
  }
  deleteCost(id) {
    return axios.delete(`/cost`, { data: { id } });
  }
}

export default new Cost({});
