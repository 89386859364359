import React, { useState } from "react";
import {
  TextField,
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  IconButton,
  Divider,
  Modal,
  Alert,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import InputWrap from "../../components/InputWrap";
import { useSnackbar } from "notistack";

import TelegramService from '../../services/Telegram'

export default function TelegramSend({ data, closeHandler }) {
  const { enqueueSnackbar } = useSnackbar();

  let [isSubmiting, setSubmiting] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      message: "",
    },
  });

  const onSubmit = handleSubmit((d) => {
    setSubmiting(true);
    TelegramService.send(Object.assign({}, d, { clients: data }))
      .then(() => {
        enqueueSnackbar("Telegram сообщения отправлены!", { variant: "success" });
        closeHandler();
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  return (
    <Box>
      <Alert icon={false} severity="info">
        Будет отправлено {data.length} клиентам!
      </Alert>
      <form noValidate onSubmit={onSubmit}>
        <Controller
          name={"message"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputWrap wrapStyle={{ my: 1 }} id="message" label="Сообщение">
              <TextField
                id="message"
                label=""
                error={Boolean(errors.message?.message)}
                {...register("message", {
                  required: "Сообщение не может быть пустым",
                  minLength: {
                    message: "Мин. 4 символов",
                    value: 4,
                  },
                  maxLength: 200,
                })}
                fullWidth
                margin="normal"
                helperText={errors.message?.message}
                sx={{ m: 0 }}
                size="small"
                variant="outlined"
                name="message"
                type="text"
                onChange={onChange}
                value={value}
                multiline
                rows={4}
              />
            </InputWrap>
          )}
        />
        <Button
          fullWidth
          sx={{ marginTop: 2 }}
          size="large"
          variant="contained"
          type="submit"
          startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmiting}
        >
          Отправить
        </Button>
      </form>
    </Box>
  );
}
