import React, { useState, useEffect } from "react"
import { Box, Button } from "@mui/material"
import MessageService from "../../../services/Message"
import { socket } from "../../../socket"
import ClientService from "../../../services/Client"

// Store
import { observer } from "mobx-react-lite"
import { EventBus } from "../../../event"
import AddIcon from "@mui/icons-material/Add"
import { useCallback } from "react"
import { useRef } from "react"

function Chat() {
    let [list, setList] = useState([])

    useEffect(() => {
        socket.on("update_feedback", (feedback) => {
            updateFeedbackHandler(feedback)
        })
        return () => {
            socket.off("update_feedback")
        }
    }, [])

    // Works if only there is less than 10 messages per 3 seconds
    const [pollingPage, setPollingPage] = useState([])

    // Messages from new pages
    const [isMessagesLoading, setMessagesLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [isAllLoaded, setAllLoaded] = useState(false)

    const loadingObserverRef = useRef(null)

    useEffect(() => {
        ;(async () => {
            while (true) {
                if (isMessagesLoading && !isAllLoaded) {
                    const { data: messages } =
                        await MessageService.getPage(page)

                    if (messages.length == 0) {
                        setAllLoaded(true)
                        return
                    }

                    while (
                        list.findIndex(
                            (message) => messages[0].phone == message.phone,
                        ) != -1
                    ) {
                        if (messages.length == 0) {
                            setPage((prev) => prev + 1)
                            continue
                        }

                        messages.shift()
                    }

                    setList((prev) => prev.concat(messages))
                    setPage((prev) => prev + 1)
                    setMessagesLoading(false)
                }

                break
            }
        })()
    }, [isMessagesLoading])

    const pollMessages = async () => {
        const { data: messages } = await MessageService.getPage(0)

        setPollingPage(messages)
    }

    useEffect(() => {
        const interval = setInterval(pollMessages, 3000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (!pollingPage) {
            return
        }

        const newMessages = []

        for (let i = 0; i < pollingPage.length; i++) {
            if (list[0].lastMessage._id == pollingPage[i].lastMessage._id) {
                break
            }

            newMessages.push(pollingPage[i])
        }

        if (newMessages.length != 0) {
            setList((prev) => newMessages.concat(prev))
        }
    }, [pollingPage])

    useEffect(() => {
        if (!loadingObserverRef.current) {
            return
        }

        const element = loadingObserverRef.current

        const observer = new IntersectionObserver((e) => {
            if (
                e
                    .map((intersection) => intersection.isIntersecting)
                    .reduce((prev, cur) => prev || cur, false) == true
            ) {
                setMessagesLoading(true)
            }
        })

        observer.observe(element)

        return () => {
            if (element) {
                observer.unobserve(element)
            }
        }
    }, [loadingObserverRef])

    function updateFeedbackHandler(feedback) {
        setList((list) =>
            list.map((f) => (f._id == feedback._id ? feedback : f)),
        )
    }

    return (
        <Box>
            <Button
                sx={{ mb: 2 }}
                size="small"
                variant="contained"
                onClick={() => EventBus.$emit("open_edit_message", {})}
                startIcon={<AddIcon />}
            >
                Написать
            </Button>
            <div
                style={{
                    background: "#434242",
                    padding: "0 20px",
                    borderRadius: "10px",
                }}
            >
                {list.map((user) => (
                    <div
                        onClick={() =>
                            EventBus.$emit("open_chat", { phone: user.phone })
                        }
                        style={{
                            padding: "20px",
                            display: "grid",
                            gridGap: "10px",
                            gridTemplateColumns: "1fr auto",
                            borderBottom: "1px grey solid",
                            cursor: "pointer",
                        }}
                        key={user._id}
                    >
                        <div
                            style={{
                                display: "grid",
                                alignItems: "center",
                                gridTemplateColumns: "auto 1fr",
                            }}
                        >
                            <span>
                                {user.client.name} {user.client.surname}{" "}
                            </span>
                            {!user.lastMessage.hasBeenRead &&
                                user.lastMessage.to === "admin" && (
                                    <div
                                        style={{
                                            background: "#FF0009",
                                            width: "15px",
                                            height: "15px",
                                            borderRadius: "50%",
                                            display: "inline-block",
                                            marginLeft: "15px",
                                        }}
                                    />
                                )}
                        </div>
                        <span style={{ color: "#a6a6a6" }}>
                            {user.lastMessage.created_at
                                .split("T")[0]
                                .split("-")
                                .reverse()
                                .join(".")}
                            ,{" "}
                            {parseInt(
                                user.lastMessage.created_at
                                    .split("T")[1]
                                    .split(":")[0],
                            ) > 20
                                ? parseInt(
                                      user.lastMessage.created_at
                                          .split("T")[1]
                                          .split(":")[0],
                                  ) +
                                  3 -
                                  24
                                : parseInt(
                                      user.lastMessage.created_at
                                          .split("T")[1]
                                          .split(":")[0],
                                  ) + 3}
                            :
                            {
                                user.lastMessage.created_at
                                    .split("T")[1]
                                    .split(":")[1]
                            }
                        </span>
                        <span style={{ color: "#a6a6a6" }}>
                            {user.lastMessage.from === "admin" && <b>Вы: </b>}
                            {user.lastMessage.text &&
                            user.lastMessage.text.length > 0
                                ? user.lastMessage.text
                                : user.lastMessage.file
                                  ? "Фото"
                                  : ""}
                        </span>
                    </div>
                ))}
                <div ref={loadingObserverRef} />
            </div>
        </Box>
    )
}

export default observer(Chat)
