import React from "react";
import {
    Card,
    Typography,
    Grid,
} from "@mui/material";

function BoardItem({ name, desc }) {
    return (
        <Grid item xs={4} md={2} lg={1.3}>
            <Card sx={{ p: 2, width: "100%", minHeight: "100%" }}>
                <Typography sx={{ fontSize: "12px" }} variant="h6" align="center">
                    {name}
                </Typography>
                <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ mt: 1 }}
                    align="center"
                >
                    {desc}
                </Typography>
            </Card>
        </Grid>
    );
}

// consumption: 117
// countPayment: 1
// initApps: 0
// newRegs: 1
// openClients: 506
// profit: 3783
// revenue: 3900
// trainerSalary: 117

function Board({ data }) {
    console.log(data, "data");

    return (
        <Grid sx={{ my: 2 }} container alignItems="stretch" spacing={2}>
            <BoardItem name="Выручка" desc={`${data.revenue} ₽`} />
            <BoardItem name="Расход" desc={`${data.consumption} ₽`} />

            {data.costs.result.map(c => <BoardItem name={c.name} desc={`${c.sum} ₽`} />)}

            <BoardItem name="ЗП Тренеров" desc={`${data.trainerSalary} ₽`} />
            <BoardItem name="Прибыль" desc={`${data.profit} ₽`} />
            <BoardItem name="Средний чек" desc={`${data.averageCheck} ₽`} />
            {/* <BoardItem name="Клиенты (открыт)" desc={data.openClients} /> */}
            <BoardItem name="Кол-во оплат" desc={data.countPayment} />
            <BoardItem name="Новые регистрации" desc={data.newRegs} />
            <BoardItem name="Уста-но приложений" desc={data.initApps} />
            <BoardItem name="Сумма бонусов" desc={data.allBalances} />
            <BoardItem name="Сумма перерасчетов" desc={data.recalcSum} />

        </Grid>
    );
}

export default Board
