import axios from "axios";

class Telegram {
  getByClient(clientId) {
    return axios.get(`/telegram/by-client`, { params: { clientId } });
  }
  getAll() {
    return axios.get(`/telegram`);
  }
  send(data) {
    return axios.post(`/telegram`, data);
  }
}

export default new Telegram({});
