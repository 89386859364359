import { useState } from "react";
import { Box, Grid, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AdditionalNumbersModal from "./AdditionalNumbersModal";

export default function AdditionalNumbers({ list, clientId }) {
    let [numbers, setNumbers] = useState(list);
    let [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(!showModal);
    };

    if (!numbers) {
        return <div></div>
    }

    return (
        <Card
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                bgcolor: "rgba(255,255,255,0.1)",
                p: 1,
            }}
        >
            <AdditionalNumbersModal
                numbers={numbers}
                setNumbers={setNumbers}
                handleClose={handleClose}
                open={showModal}
                clientId={clientId}
            />
            <Box sx={{ flexGrow: 1 }}>
                {numbers.map((n) => (
                    <p>{n}</p>
                ))}
            </Box>
            <IconButton onClick={handleClose}>
                <EditIcon />
            </IconButton>
        </Card>
    );
}
