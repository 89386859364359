import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Card,
  TableContainer,
  Grid,
  TableRow,
  TableCell,
  Button,
  Paper,
  Dialog,
  CircularProgress,
  TableBody,
  TableHead,
  ListItem,
  List,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import InputWrap from "../../components/InputWrap";
import { useSnackbar } from "notistack";

import CostService from "../../services/Cost";

import { useForm, Controller } from "react-hook-form";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

function CostCategory() {
  const { enqueueSnackbar } = useSnackbar();
  const [showAdd, setShowAdd] = useState(false);
  const [changeData, setChangeData] = useState(null);
  const [list, setList] = useState([]);

  useEffect(() => {
    CostService.getAllCategory().then((r) => setList(r.data));
  }, []);

  function addCategory(category) {
    setList((l) => [category, ...l]);
  }
  function deleteCategory(id) {
    CostService.deleteCategory(id).then((r) => {
      setList((l) => l.filter((i) => i._id !== id));
      enqueueSnackbar("Категория удалена!", { variant: "success" });
    });
  }
  function changeCategory(data) {
    setList((l) => l.map((i) => (i._id == data._id ? data : i)));
    enqueueSnackbar("Категория измененна!", { variant: "success" });
  }

  const toggleShowAdd = () => {
    setShowAdd(!showAdd);
    setChangeData(null);
  };
  return (
    <Card sx={{ p: 1.5, mt: 6 }}>
      <AddCoastCategory
        data={changeData}
        addCategory={addCategory}
        show={showAdd}
        toggleShowAdd={toggleShowAdd}
        changeCategory={changeCategory}
      />
      <Typography sx={{ mb: 2 }} variant="body1" gutterBottom>
        Категории расходов
      </Typography>
      <Button
        sx={{ mb: 2 }}
        size="small"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={toggleShowAdd}
      >
        Добавить
      </Button>
      <List>
        {list.map((c) => {
          return (
            <ListItem
              secondaryAction={
                <Box>
                  <IconButton
                    onClick={() => {
                      setChangeData(c);
                      setShowAdd(true);
                    }}
                    aria-label="comment"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => deleteCategory(c._id)}
                    aria-label="comment"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              }
            >
              <ListItemText>{c.name}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
}

function AddCoastCategory({
  show,
  toggleShowAdd,
  data,
  addCategory,
  changeCategory,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (data) {
      setValue("name", data.name);
    } else {
      setValue("name", "");
    }
  }, [show]);

  const onSubmit = handleSubmit((d) => {
    setIsSubmiting(true);
    if (data) {
      CostService.changeCategory({
        _id: data._id,
        name: d.name
      })
        .then((r) => {
          changeCategory(r.data);
          toggleShowAdd();
        })
        .catch((e) => {
          let message = e.response?.data?.message;
          if (message) {
            enqueueSnackbar(message, { variant: "error" });
          }
        })
        .finally(() => setIsSubmiting(false));
    } else {
      CostService.addCategory(d)
        .then((r) => {
          addCategory(r.data);
          enqueueSnackbar("Категория добавлена!", { variant: "success" });
          toggleShowAdd();
        })
        .catch((e) => {
          let message = e.response?.data?.message;
          if (message) {
            enqueueSnackbar(message, { variant: "error" });
          }
        })
        .finally(() => setIsSubmiting(false));
    }
  });

  return (
    <Box>
      <Dialog
        open={show}
        onClose={toggleShowAdd}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 1.5, mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" gutterBottom>
              {data ? "Изменить" : "Добавить"} категорию
            </Typography>
            <IconButton sx={{ ml: 3 }} onClick={toggleShowAdd}>
              <CloseIcon />
            </IconButton>
          </Box>

          <form noValidate onSubmit={onSubmit}>
            <Controller
              name={"name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputWrap
                  wrapStyle={{ my: 1 }}
                  id="name"
                  label="Название категории"
                >
                  <TextField
                    id="surname"
                    label=""
                    error={Boolean(errors.name?.message)}
                    {...register("name", {
                      required: "Название не может быть пустым",
                      minLength: {
                        message: "Мин. 4 символов",
                        value: 4,
                      },
                      maxLength: 200,
                    })}
                    fullWidth
                    margin="normal"
                    helperText={errors.name?.message}
                    sx={{ m: 0 }}
                    size="small"
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                </InputWrap>
              )}
            />
            <Button
              fullWidth
              sx={{ marginTop: 2 }}
              variant="contained"
              type="submit"
              startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmiting}
            >
              {!data ? "Добавить" : "Сохранить"}
            </Button>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
}

export default CostCategory;
