import React, { useEffect, useState } from "react";
import {
    Alert,
    Box,
    MenuList,
    Button,
    MenuItem,
    Avatar,
    Popover,
    Typography,
    IconButton,
    Chip,
    Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import ClientService from "../../services/Client";
import moment from "moment";
import "moment/locale/ru";
import getAgeByBirthday from "../../common/getAgeByBirthday";
import AddIcon from "@mui/icons-material/Add";
import PaidIcon from "@mui/icons-material/Paid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { EventBus } from "../../event";
import getClientStatusName from "../../common/getClientStatusName";
import constants from "../../constants";
import { useSnackbar } from "notistack";
import { socket } from "../../socket";
import getPrivateImageUri from "../../common/file/getPrivateImageUri";
import ChangePhoneNumber from "./ChangePhoneNumber";
import AdditionalNumbers from "./AdditionalNumbers";

import clubStore from "../../store/club";
import { observer } from "mobx-react-lite";

// Modals
import ChangeClub from "./Modals/ChangeClub";

function InfoItem({ name, value, isBold, editHandler }) {
    return (
        <Grid container sx={{ mb: 1 }} spacing={1}>
            <Grid item xs={6}>
                <Typography variant="body1" fontWeight="medium">
                    {name}
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex" }}>
                <Typography variant="body1" fontWeight={isBold ? "bold" : "regular"}>
                    {value}
                </Typography>
                {!!editHandler && (
                    <IconButton onClick={editHandler} sx={{ ml: 1, p: 0 }}>
                        <EditIcon />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
}
function AboutSection({ id, closeHandler }) {
    const { enqueueSnackbar } = useSnackbar();
    let [showAlert, setShowAlert] = useState(false);
    const [showSellMenu, setShowSellMenu] = useState(null);
    const [showStatusList, setShowStatusList] = useState(null);
    const [showChangePhoneModal, setShowChangePhoneModal] = useState(false);
    const [showChangeClubModal, setShowChangeClubModal] = useState(false);

    let [userData, setUserData] = useState(null);

    let clubList = clubStore.list;

    function fetchClientData() {
        ClientService.getOne(id).then((r) => setUserData(r.data));
    }

    useEffect(() => {
        fetchClientData();
        socket.on("update_client", (u) => {
            console.log(u, "update client");
            setUserData((client) => {
                return client._id == u._id ? u : client;
            });
        });

        return () => {
            socket.off("update_client");
        };
    }, []);

    const handleClickSellMenu = (event) => {
        setShowSellMenu(true);
    };

    const editPhoneOpen = () => {
        setShowChangePhoneModal(true);
    };
    const changeClubOpen = () => {
        setShowChangeClubModal(true);
    };
    const handleCloseSellMenu = () => {
        setShowSellMenu(false);
    };

    const deleteClientHandler = (id) => {
        if (!showAlert) {
            setShowAlert(true);
            return;
        }
        if (window.confirm("Вы уверены?")) {
            ClientService.delete(id)
                .then(() => {
                    enqueueSnackbar("Клиент удален!", { variant: "success" });
                    closeHandler();
                })
                .catch((err) => { });
        }
    };

    function userChangeStatus(l) {
        ClientService.changeStatus({ id: userData._id, status: l }).then((r) => {
            enqueueSnackbar("Статус клиента изменён!", { variant: "success" });
        });
    }

    if (!userData) return null;

    let levelName = userData.account.cashback.name;

    let levelProgress = userData.account.cashback.progress;

    let age = getAgeByBirthday(
        userData.isChild ? userData.child.birthday : userData.birthday
    );

    age = `${age} (${moment(
        userData.isChild ? userData.child.birthday : userData.birthday
    ).format("DD.MM.YYYY")})`;

    let club = clubList.find((c) => c._id == userData?.account?.club);

    // userData?.account?.club ==

    // list

    return (
        <Box>
            <ChangePhoneNumber
                id={userData._id}
                phone={userData.phone}
                showModal={showChangePhoneModal}
                setShowModal={setShowChangePhoneModal}
            />
            <ChangeClub
                initClub={userData?.account?.club}
                accountId={userData?.account?._id}
                showModal={showChangeClubModal}
                setShowModal={setShowChangeClubModal}
            />
            <Avatar
                variant="square"
                sx={{ width: "100%", height: "300px", mb: 4 }}
                src={getPrivateImageUri(userData.photo)}
            />
            <Button
                fullWidth
                sx={{}}
                size="small"
                variant="contained"
                startIcon={<AddIcon />}
                endIcon={<ArrowDropDownIcon />}
                onClick={handleClickSellMenu}
                id={id}
            >
                Продать
            </Button>
            {showSellMenu && (
                <Box sx={{ mt: 2 }} onClick={handleCloseSellMenu}>
                    <Button
                        onClick={() =>
                            EventBus.$emit("open_create_subscripe_window", {
                                clientId: userData._id,
                                balance: userData.account.balance,
                            })
                        }
                        fullWidth
                        size="small"
                        variant="outlined"
                    >
                        Абонемент
                    </Button>
                </Box>
            )}
            <Button
                fullWidth
                sx={{ mt: 2 }}
                size="small"
                variant="contained"
                endIcon={<PaidIcon />}
                onClick={() => EventBus.$emit("open_client_bonus_window", userData._id)}
            >
                Бонусы
            </Button>

            <Box
                id={showStatusList}
                onClick={(event) => setShowStatusList(event.currentTarget)}
            >
                <Chip
                    sx={{
                        mt: 6,
                        // width: "100%",
                        mb: 2,
                        color: "white",
                        bgcolor: constants.clientStatusChip[userData.status],
                    }}
                    label={getClientStatusName(userData.status)}
                />
            </Box>

            <Popover
                id={showStatusList}
                open={!!showStatusList}
                anchorEl={showStatusList}
                onClose={() => setShowStatusList(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <MenuList>
                    {["open", "close", "block"].map((l, i) => (
                        <MenuItem
                            onClick={() => {
                                setShowStatusList(null);
                                userChangeStatus(l);
                            }}
                            sx={{
                                color: "white",
                                bgcolor: constants.clientStatusChip[l],
                                "&:hover": {
                                    bgcolor: constants.clientStatusChip[l],
                                },
                            }}
                            key={i}
                        >
                            {getClientStatusName(l)}
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>

            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography variant="h6" fontWeight="regular" component="div">
                    {userData.isChild
                        ? `${userData.child.surname} ${userData.child.name} ${userData.child.patronymic}`
                        : `${userData.surname} ${userData.name} ${userData.patronymic}`}
                </Typography>
                <IconButton
                    onClick={() => EventBus.$emit("open_edit_client_window", userData)}
                    sx={{ ml: 1 }}
                >
                    <EditIcon size="large" />
                </IconButton>
            </Box>

            <Box>
                {userData.isChild && (
                    <InfoItem
                        name="Родитель"
                        value={`${userData.surname} ${userData.name} ${userData.patronymic
                            } ${getAgeByBirthday(userData.birthday) || ""}`}
                    />
                )}
                <InfoItem
                    name="Регистрация"
                    value={moment(userData.created_at).format("DD.MM.YYYY, в HH:mm")}
                />
                <InfoItem
                    name="Телефон"
                    value={`+${userData.phone}`}
                    editHandler={editPhoneOpen}
                />

                <AdditionalNumbers
                    list={userData.account.additionalNumbers}
                    clientId={id}
                />
                <InfoItem name="Возраст" value={age} />
                <InfoItem name="Район" value={userData.district} />

                {userData.telegram && (
                    <InfoItem name="Telegram" isBold value={`@${userData.telegram}`} />
                )}

                <InfoItem
                    name="Баланс"
                    isBold
                    value={`${userData.account.balance} Б`}
                />
                <InfoItem
                    name="Уровень"
                    isBold
                    value={`${levelName}${levelProgress ? `(${levelProgress})` : ""}`}
                />
                <InfoItem
                    name="Клуб"
                    value={club.address}
                    editHandler={changeClubOpen}
                />
            </Box>
            {showAlert && (
                <Alert severity="error">
                    Удаление клиента также удалит его заявки и абонементы!
                </Alert>
            )}

            <Button
                fullWidth
                sx={{ mt: 2 }}
                size="small"
                variant="contained"
                endIcon={<DeleteForeverIcon />}
                onClick={() => deleteClientHandler(userData._id)}
            >
                Удалить
            </Button>
        </Box>
    );
}

export default observer(AboutSection);
