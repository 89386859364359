import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
} from "@mui/material";
import MessageService from "../../../services/Message";
import { socket } from "../../../socket";
import ClientService from "../../../services/Client";
import FeedbackTable from "../../../components/FeedbackTable/FeedbackTable";

// Store
import ClubStore from "../../../store/club";
import { observer } from "mobx-react-lite";
import { EventBus } from "../../../event";
import AddIcon from "@mui/icons-material/Add";

function Chat({ clientId }) {
    let [list, setList] = useState([]);
    let [getNewMessages, setGetNewMessages] = useState(true);
    useEffect(() => {
        socket.on("update_feedback", (feedback) => {
            updateFeedbackHandler(feedback);
        });
        return () => {
            socket.off("update_feedback");
        };
    }, []);

    useEffect(() => {
        MessageService.getAll().then((r) => setList(r.data));
    }, []);

    useEffect(() => {
        if (getNewMessages) {
            setGetNewMessages(false)
            setTimeout(() => {
                MessageService.getAll().then((r) => setList(r.data));
                setGetNewMessages(true)
            }, 3000)
        }
    }, [getNewMessages]);

    let [userData, setUserData] = useState(null);

    function updateFeedbackHandler(feedback) {
        setList((list) => list.map((f) => (f._id == feedback._id ? feedback : f)));
    }

    // console.log(list[0])

    useEffect(() => {

        ClientService.getOne(clientId).then((r) => setUserData(r.data));

    }, []);
    // useEffect(() => {
    //     console.log(userData)
    // }, [userData]);

    return (
        <Box>
            <Button
                sx={{ mb: 2 }}
                size="small"
                variant="contained"
                onClick={() => EventBus.$emit("open_edit_message", {})}
                startIcon={<AddIcon />}
            >
                Написать
            </Button>
            <div style={{ background: '#434242', padding: '0 20px', borderRadius: '10px' }}>
                {userData && list.filter(user => user.phone === userData.phone).map(user =>
                    <div onClick={() => EventBus.$emit("open_chat", { phone: user.phone })} style={{ padding: '20px', display: 'grid', gridGap: '10px', gridTemplateColumns: '1fr auto', borderBottom: '1px grey solid' }}>
                        <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 1fr' }}>
                            <span>{user.client.name} {user.client.surname} </span>
                            {!user.lastMessage.hasBeenRead && user.lastMessage.to === 'admin' && <div style={{ background: '#FF0009', width: '15px', height: '15px', borderRadius: '50%', display: 'inline-block', marginLeft: '15px', }} />}
                        </div>
                        <span style={{ color: '#a6a6a6' }}>{user.lastMessage.created_at.split('T')[0].split('-').reverse().join('.')}, {parseInt(user.lastMessage.created_at.split('T')[1].split(':')[0]) > 20 ? parseInt(user.lastMessage.created_at.split('T')[1].split(':')[0]) + 3 - 24 : parseInt(user.lastMessage.created_at.split('T')[1].split(':')[0]) + 3}:{user.lastMessage.created_at.split('T')[1].split(':')[1]}</span>
                        <span style={{ color: '#a6a6a6' }}>{user.lastMessage.from === 'admin' && <b>Вы: </b>}{user.lastMessage.text && user.lastMessage.text.length > 0 ? user.lastMessage.text : user.lastMessage.file ? 'Фото' : ''}</span>
                    </div>
                )}
            </div>
        </Box>
    );
}

export default observer(Chat);
