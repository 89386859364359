import { useState, useEffect } from "react";

// Mui
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Services
import ServiceClub from "../../services/Club";

// Mobx
import { observer } from "mobx-react-lite";

// Store
import ClubStore from "../../store/club";

function SelectClub() {
  let clubs = ClubStore.list;
  let currentClubId = ClubStore.currentClubId;

  useEffect(() => {
    if (clubs.length && !currentClubId) {
      ClubStore.setCurrentClubId(clubs[0]._id);
    }
  }, [clubs, currentClubId]);

  if (!clubs) return null;

  return (
    <Box sx={{ mb: 1, display: "flex", justifyContent: "flex-end" }}>
      <Box sx={{ minWidth: "250px" }}>
        <FormControl size="small" fullWidth>
          <InputLabel isize="small">Клуб</InputLabel>
          <Select
            value={currentClubId}
            label="Клуб"
            onChange={(e) => {
              ClubStore.setCurrentClubId(e.target.value);
              window.location.reload();
            }}
          >
            {clubs.map((c) => {
              return <MenuItem value={c._id}>{c.address}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

export default observer(SelectClub);
