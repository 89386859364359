import axios from "axios";

class Message {
  send(data) {
    return axios.post(`/message`, data);
  }
  getAll() {
    return axios.get(`/messages`);
  }
  getChat(data) {
    return axios.post(`/messages/chat`, data);
  }

  getPage(page) {
    return axios.get(`/messages/pages?page=${page}`);
  }
}

export default new Message({});
