import React from "react";
import {
  Box,
  TablePagination,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Popover,
  Divider,
  Typography,
  SwipeableDrawer,
  Button,
  IconButton,
  Menu,
  MenuList,
  Chip,
  MenuItem,
  ListItemText,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import moment from "moment";
import "moment/locale/ru";
import { EventBus } from "../../event";
import DeleteIcon from "@mui/icons-material/Delete";
import firstLetterUppercase from "../../common/firstLetterUppercase";

export default function CostTable({
  list,
  paginData,
  handleChangePage,
  handleChangeRowsPerPage,
  deleteHandler,
}) {
  return (
    <Box>
      <TableContainer sx={{ maxWidth: "100%", overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell>Категория</TableCell>
              <TableCell>Сумма</TableCell>
              <TableCell>Дата оплаты</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((r) => {
              // let client = r.client;
              // let clientFullName = `${client.surname} ${client.name} ${client.patronymic}`;
              // let childFullName = "-";

              // if (client.isChild) {
              //   childFullName = `${client.child.surname} ${client.child.name} ${client.child.patronymic}`;
              // }

              // let monthName = moment(r.month).format("MMMM");
              // monthName = firstLetterUppercase(monthName);
              // let dateText = moment(r.datePayment).format("DD.MM.YYYY, HH:mm");

              return (
                <TableRow key={r._id}>
                  <TableCell>{r.name}</TableCell>
                  <TableCell>{r.category.name}</TableCell>
                  <TableCell>{r.sum}</TableCell>
                  <TableCell>
                    {moment(r.date).format("DD.MM.YYYY")}
                  </TableCell>
                  <TableCell>
                    {moment(r.created_at).format("DD.MM.YYYY, HH:mm")}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => deleteHandler(r._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[5, 10, 25]}
        component={Paper}
        count={paginData.countRows}
        rowsPerPage={paginData.rowsPerPage}
        page={paginData.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
