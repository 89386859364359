import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControlLabel,
  Box,
  Card,
  Checkbox,
  Container,
  Table,
  TableBody,
  TableCell,
  MenuList,
  TableContainer,
  TableHead,
  Collapse,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  Avatar,
  CircularProgress,
  Paper,
  List,
  ListItem,
  Popover,
  ListItemButton,
  ListItemText,
  Typography,
  Menu,
  IconButton,
  Popper,
  Chip,
  Grid,
  Modal,
} from "@mui/material";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

// Services
import ClientService from "../../../services/Client";

//Store
import ClubStore from "../../../store/club";

export default function ChangePhoneNumber({
  showModal,
  setShowModal,
  accountId,
  initClub,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmiting, setSubmiting] = useState(false);
  const [selectClub, setSelectClub] = useState(initClub);

  // Calc
  let clubs = ClubStore.list;


  const onSubmit = (e) => {
    e.preventDefault();

    setSubmiting(true);
    ClientService.changeClub({
      accountId: accountId,
      clubId: selectClub,
    })
      .then(() => {
        enqueueSnackbar("Клуб изменён!", { variant: "success" });
        setShowModal(false);
      })
      .finally(() => setSubmiting(false));
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 1,
          }}
        >
          <Typography variant="h6" component="h2">
            Изменение клуба
          </Typography>
          <IconButton sx={{ m: 0, ml: 2 }} onClick={() => setShowModal(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box noValidate component="form" onSubmit={onSubmit} sx={{ p: 2 }}>
          <FormControl size="small" fullWidth>
            <InputLabel isize="small">Клуб</InputLabel>
            <Select
              value={selectClub}
              label="Клуб"
              onChange={(e) => setSelectClub(e.target.value)}
            >
              {clubs.map((c) => {
                return <MenuItem value={c._id}>{c.address}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <Button
            fullWidth
            sx={{ marginTop: 2 }}
            size="large"
            variant="contained"
            type="submit"
            startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmiting}
          >
            Сохранить
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
