import { React, Fragment } from "react";

import NewspaperIcon from "@mui/icons-material/Newspaper";
import GroupsIcon from "@mui/icons-material/Groups";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PeopleIcon from "@mui/icons-material/People";
import HomeIcon from "@mui/icons-material/Home";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PaidIcon from "@mui/icons-material/Paid";
import ReviewsIcon from "@mui/icons-material/Reviews";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import AssignmentIcon from "@mui/icons-material/Assignment";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import Notifications from '@mui/icons-material/Notifications';




// {
//     link: "/support",
//         title: "Тех. поддержка",
//     icon: QuestionAnswerIcon,
// },
const list = [
    {
        link: "/home",
        title: (
            <Fragment>
                Рабочий
                <br />
                стол
            </Fragment>
        ),
        icon: HomeIcon,
    },
    {
        link: "/cost",
        title: 'Расходы',
        icon: PointOfSaleIcon,
    },
    {
        link: "/purchases",
        title: "Покупки",
        icon: HistoryToggleOffIcon,
    },
    {
        link: "/orders",
        title: "Заявки",
        icon: BorderColorIcon,
    },
    {
        link: "/clients",
        title: "Клиенты",
        icon: ContactPageIcon,
    },
    {
        link: "/notifications",
        title: "Уведомления",
        icon: Notifications,
    },
    {
        submenu: "app",
        title: (
            <Fragment>
                Мобильное
                <br />
                приложение
            </Fragment>
        ),
        icon: AppSettingsAltIcon,
        list: [
            {
                link: "/news",
                title: "Новости",
                icon: NewspaperIcon,
            },
            {
                link: "/reviews",
                title: "Отзывы",
                icon: ReviewsIcon,
            },
            {
                link: "/offers",
                title: "Оферта",
                icon: AssignmentIcon,
            },
            {
                link: "/appSettings",
                title: "Настройки приложения",
                icon: SettingsApplicationsIcon,
            },
        ],
    },
    {
        submenu: "settings",
        title: "Настройки",
        icon: SettingsApplicationsIcon,
        list: [
            {
                link: "/funnel-stages",
                title: (
                    <Fragment>
                        Этапы <br /> воронки
                    </Fragment>
                ),
                icon: FilterAltIcon,
            },
            {
                link: "/global-settings",
                title: "Общие",
                icon: SelectAllIcon,
            },
        ],
    },
    {
        link: "/my-club",
        title: "Мой клуб",
        icon: MapsHomeWorkIcon,
    },
    {
        link: "/trainers",
        title: "Тренеры",
        icon: GroupsIcon,
    },
    {
        link: "/groups",
        title: "Группы",
        icon: PeopleIcon,
    },
    {
        link: "/wage",
        title: (
            <Fragment>
                Заработная <br />
                плата
            </Fragment>
        ),
        icon: PaidIcon,
    },
    {
        link: "/communication",
        title: "Коммуникация",
        icon: QuestionAnswerIcon,
    },
];

export default list;