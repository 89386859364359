import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Typography,
  CircularProgress,
  SwipeableDrawer,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tabs,
  Tab,
} from "@mui/material";
import TelegramService from "../../../services/Telegram";
import TelegramTable from "../../../components/TelegramTable/TelegramTable";

// Store
import ClubStore from '../../../store/club'
import {observer} from 'mobx-react-lite';

function Telegram() {
  let clubId = ClubStore.currentClubId;
  let [list, setList] = useState([]);
  useEffect(() => {
    TelegramService.getAll().then((r) => setList(r.data));
  }, [clubId]);
  return (
    <Box>
      <TelegramTable list={list} />
    </Box>
  );
}

export default observer(Telegram);
